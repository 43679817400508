import React, { Component } from 'react';

import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import axios from 'axios'
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import { Link, Tooltip } from '@mui/material';
import { DialogContent, Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';



import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
class ShowcaseApp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disclaimerDialogOpen: false,
            avatarStep: 0,
            avatar_busy: true,
            avatarRec: null,
            cardExpand: false
        };


        this.handleDisclaimerDialogClose = this.handleDisclaimerDialogClose.bind(this);
        this.handleDisclaimerDialogOpen = this.handleDisclaimerDialogOpen.bind(this);
        this.handleExpandMore = this.handleExpandMore.bind(this);
        this.refreshAvatar = this.refreshAvatar.bind(this);
    }


    handleDisclaimerDialogClose(m) {
        this.setState({ disclaimerDialogOpen: false });
    }
    handleDisclaimerDialogOpen(m) {
        this.setState({ disclaimerDialogOpen: true });
    }
    handleExpandMore() {

        let exp = !this.state.cardExpand;
        this.setState({ cardExpand: exp });
    }

    refreshAvatar() {

        axios.get(this.props.act_ip + '/dalle/random', {
            headers: {
            },
            params: {
                number: 1
            }
        })
            .then(response => {
                console.log("dalle", response);
                this.props.onInfoMsg("success");
                let avatarRec = [];
                avatarRec = response.data.recs[0];
                this.setState({
                    busy: false,
                    avatarRec: avatarRec,
                    cardExpand: false
                });
            }).catch(error => {
                this.props.onErrorMsg('wtf');
            }).finally(() => { this.setState({ avatar_busy: false }) });
    }


    componentDidMount() {
        console.log('mounting');
        console.log("dalle", this.props);
        this.refreshAvatar();
    }

    render() {
        console.log('rendering');

        return (
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid item xs={4} sm={8} md={12}  >
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 50,
                                    pl: 2,
                                    bgcolor: 'background.default',
                                }}
                            >
                            </Paper>
                            <div >
                                {this.state.avatarRec ?
                                    <Card sx={{ maxWidth: 400 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar src={this.props.img_ip + this.state.avatarRec.thumb_uri}>
                                                </Avatar>
                                            }
                                            title={this.state.avatarRec.name}
                                            subheader={this.state.avatarRec.class}
                                        />
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image={this.props.img_ip + this.state.avatarRec.uri}
                                        />
                                        <CardActions disableSpacing>
                                            <IconButton aria-label="share" onClick={this.refreshAvatar}>
                                                <RefreshIcon />

                                            </IconButton>
                                            <ExpandMore
                                                expand={this.state.cardExpand}
                                                onClick={() => { this.handleExpandMore() }}
                                                aria-expanded={this.state.cardExpand}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </CardActions>
                                        <Collapse in={this.state.cardExpand} timeout="auto" unmountOnExit>
                                            <CardContent>
                                                <Typography paragraph>Story:</Typography>
                                                <Typography paragraph>
                                                    {this.state.avatarRec.backstory}
                                                </Typography>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                    : null}
                            </div>
                            <LinearProgress value={100} variant={this.state.avatar_busy ? 'indeterminate' : 'determinate'} />
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={this.state.disclaimerDialogOpen} onClose={this.handleDisclaimerDialogClose}>
                    <DialogTitle>NpcHub Disclaimer</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            All minted NFTs will be transfered to the wallet used to mint the NFT.  Never upload any personally identifiable information.  A small service charge will be added.  A royalty will be charged in the event the NFT is sold.  Uploaded images may be used to improve the tool and user experience.

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisclaimerDialogClose}>Close</Button>
                    </DialogActions>
                </Dialog>

            </Box>
        )
    }
}

export default ShowcaseApp
