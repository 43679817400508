
import React, { Component } from 'react';

import axios from 'axios'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Typography from '@mui/material/Typography';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import Slider from '@mui/material/Slider';
import { autoPlay } from 'react-swipeable-views-utils';
import { Tooltip } from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class BeyondSelectURL extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assetURI: null,
            avatarStep: 0,
            avatarStyle: 'realistic',
            avatarGender: 'unspecified',
            avatarShot: 'upper',
            avatarSetting: 'majestic',
            avatarScene: 'battle',
            avatar_busy: false,
            avatar_stage: 'Ready',
            avatar_progress: 0,
            urlSelected: false,
            avatarURIs: [],
            avatarPrompts: [],
            beyondURL: 'https://',
            numImages: 1,
            temperature: .2 
        };
        this.updateBeyondURL = this.updateBeyondURL.bind(this);
        this.handleBeyondURLChange = this.handleBeyondURLChange.bind(this);
        this.handleNumberImagesChange = this.handleNumberImagesChange.bind(this);
        this.handleTemperatureChange = this.handleTemperatureChange.bind(this);
    }

    componentDidMount() {
        console.log('mounting');
    }

    handleTemperatureChange(e){
        this.setState({temperature:e.target.value});
    }
    handleNumberImagesChange(e){
        this.setState({numImages:e.target.value});
    }
    handleBeyondURLChange(e){
        console.log('url ',e.target.value);
        this.setState({beyondURL:e.target.value});
    }
    updateBeyondURL(e) {
        console.log('select file', e);

        axios.get(this.props.img_ip + '/asset', {
            params: {
                account: this.props.account
            }
        })
            .then(asset_response => {
                console.log(asset_response);
                this.props.onInfoMsg('Asset ' + asset_response.data.productID, "info");
                this.setState({
                    productID: asset_response.data.productID,
                    token: asset_response.data.token,
                });

                const url = this.props.act_ip + '/dalle/feyrle-prompt';
                const config = {
                    headers: {
                        'x-access-token': asset_response.data.token,
                        'content-type': 'multipart/form-data'
                    },
                    params: {
                        productID: asset_response.data.productID,
                        style: this.state.avatarStyle,
                        shot: this.state.avatarShot,
                        setting: this.state.avatarSetting,
                        scene: this.state.avatarScene,
                        temperature: this.state.temperature,
                        number_of_images: this.state.numImages
                    }

                }
                this.setState({ avatar_stage: 'Uploading..' });
                axios.get(url, config)
                    .then((response) => {


                        console.log("dalle", response);
                        this.props.onInfoMsg("success");
                        let avatarURIs = [];
                        let avatarPrompts = [];
                        response.data.assetURIs.forEach(_n => {
                           //avatarURIs.push(this.props.img_ip + _n);
                           avatarURIs.push("https://" + _n);

                        })
                        response.data.prompts.forEach(_n => {
                           avatarPrompts.push(_n);
                        })

                        this.setState({
                           urlSelected: true,
                           avatarURIs: avatarURIs,
                           avatarPrompts: avatarPrompts,
                           avatarName: response.data.name,
                           avatarRace: response.data.race,
                           avatarAlignment: response.data.alignment,
                           avatarClass: response.data.char_class,
                           avatarBackstory: response.data.backstory,
                           avatar_progress: 100,
                           avatar_stage: ''
                        });

                    }).catch(error => {
                        console.log(error);
                        this.props.onErrorMsg('unable to select');
                        if (error.response) {
                            if (error.response.status === 413) {
                                this.props.onErrorMsg('Upload too big!');
                            }
                        }
                    }).finally(() => { });

            }).catch(error => {
                this.props.onErrorMsg('Error' + error, "error");
            });

    }

    render() {
        console.log('rendering');
        let pane;
        if (this.state.urlSelected) {
            pane =
                <Card sx={{ maxWidth: 400, maxHeight: 800, overflow: "auto" }} style={{ backgroundColor: 'transparent', width: 400 }}>
                    <CardContent>
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 50,
                                    pl: 2,
                                    bgcolor: 'background.default',
                                }}
                            >
                            </Paper>
                            <AutoPlaySwipeableViews
                                index={this.state.avatarStep}
                                onChangeIndex={(i) => { if (!isNaN(i)) { this.setState({ avatarStep: i }) } }}
                                enableMouseEvents
                            >
                                {this.state.avatarURIs.map((step, index) => (
                                    <div key={index}>
                                        {Math.abs(this.state.avatarStep - index) <= 2 ? (
                                            <Tooltip title={this.state.avatarPrompts[index]}>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: 255,
                                                        display: 'block',
                                                        maxWidth: 400,
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                    }}
                                                    src={step}
                                                //alt={step.label}
                                                />

                                            </Tooltip>
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                            <MobileStepper
                                steps={this.state.avatarURIs.length}
                                position="static"
                                activeStep={this.state.avatarStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={() => { console.log("a", this.state.avatarStep); this.setState({ avatarStep: this.state.avatarStep + 1 }) }}
                                        disabled={this.state.avatarStep === this.state.avatarURIs.length - 1}
                                    >
                                        Next
                                        <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={() => { console.log("b", this.state.avatarStep); this.setState({ avatarStep: this.state.avatarStep - 1 }); }} disabled={this.state.avatarStep === 0}>
                                        <KeyboardArrowLeft />
                                        Back
                                    </Button>
                                }
                            />
                        </Box>
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'left',
                                    bgcolor: 'background.default',
                                    overflow: 'auto'
                                }}
                            >
                            </Paper>
                            <Typography gutterBottom variant='h5'> {this.state.avatarName}</Typography>
                            <Typography > {this.state.avatarBackstory}</Typography>
                        </Box>

                    </CardContent>
                </Card>
        } else {

            pane =
                <Card sx={{ maxWidth: 400 }} style={{ backgroundColor: 'transparent', width: 400 }}>
                    <CardContent>
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>

                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {this.state.avatar_stage}
                            </Typography>
                            <LinearProgress value={this.state.avatar_progress} variant={'determinate'} />
                        </Box>
                    </CardContent>
                </Card>
        }
        return (
            <Box sx={{ flexGrow: 1,m: 2 }}>
                <Grid container spacing={{ xs: 5, md: 5 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid item xs={4} sm={8} md={12}>
                        {pane}
                    </Grid>
                    <Grid item p={2} xs={2} sm={3} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="page-select-label">Style</InputLabel>
                            <Select
                                labelId="page-select-label"
                                id="page-select"
                                value={this.state.avatarStyle}
                                label="Style"
                                onChange={(e) => { console.log(e); this.setState({ avatarStyle: e.target.value }) }}
                            >
                                <MenuItem value='cartoon'>Cartoon</MenuItem>
                                <MenuItem value='steam'>Steam</MenuItem>
                                <MenuItem value='realistic'>Realistic</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item p={2} xs={2} sm={3} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="shot-label">Shot</InputLabel>
                            <Select
                                labelId="shot-label"
                                id="shot-select"
                                value={this.state.avatarShot}
                                label="Shot"
                                onChange={(e) => { console.log(e); this.setState({ avatarShot: e.target.value }) }}
                            >
                                <MenuItem value='portrait'>Portrait</MenuItem>
                                <MenuItem value='full'>Full</MenuItem>
                                <MenuItem value='upper'>Upper</MenuItem>
                                <MenuItem value='zoomed'>Zoomed</MenuItem>
                                <MenuItem value='high'>High</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item p={2} xs={2} sm={3} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="setting-label">Setting</InputLabel>
                            <Select
                                labelId="setting-label"
                                id="setting-select"
                                value={this.state.avatarSetting}
                                label="Setting"
                                onChange={(e) => { console.log(e); this.setState({ avatarSetting: e.target.value }) }}
                            >
                                <MenuItem value='majestic'>Majestic</MenuItem>
                                <MenuItem value='dystopian'>Dystopian</MenuItem>
                                <MenuItem value='dark'>Dark</MenuItem>
                                <MenuItem value='bright'>Bright</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item p={2} xs={2} sm={3} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="scene-label">Setting</InputLabel>
                            <Select
                                labelId="scene-label"
                                id="scene-select"
                                value={this.state.avatarScene}
                                label="Scene"
                                onChange={(e) => { console.log(e); this.setState({ avatarScene: e.target.value }) }}
                            >
                                <MenuItem value='action'>Action</MenuItem>
                                <MenuItem value='battle'>Battle</MenuItem>
                                <MenuItem value='dining'>Dining</MenuItem>
                                <MenuItem value='festivity'>Festivity</MenuItem>
                                <MenuItem value='training'>Training</MenuItem>
                                <MenuItem value='meditation'>Meditation</MenuItem>
                                <MenuItem value='melee'>Melee</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={4} sm={6} md={6}  >
                        <InputLabel id="num-images-label">Number of Images</InputLabel>
                        <Input labelID="num-images-label" className='rw-widget-input' fullWidth type='text' onChange={this.handleNumberImagesChange} value={this.state.numImages} />
                    </Grid>
                    <Grid item xs={4} sm={6} md={6}  >
                        <InputLabel id="temperature-label">Prompt Temperature</InputLabel>
                        <Input labelID="temperature-label" className='rw-widget-input' fullWidth type='text' onChange={this.handleTemperatureChange} value={this.state.temperature} />
                    </Grid>
                    <Grid item xs={4} sm={6} md={6}  >
                        <InputLabel id="beyond-url-label">Beyond URL </InputLabel>
                        <Input labelID="beyond-url-label" className='rw-widget-input' fullWidth type='text' onChange={this.handleBeyondURLChange} value={this.state.beyondURL} />
                        <Button onClick={this.updateBeyondURL}>Generate</Button>
                    </Grid>
                </Grid>
            </Box >
        )
    }
}

export default BeyondSelectURL
