

import React, { Component } from 'react';
import ReactGA, { exception } from 'react-ga'

import axios from 'axios'
import NpcHubPng from './images/npchub-icon.png'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import AppBar from '@mui/material/AppBar';
import AvatarSelect from './AvatarSelect';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletIcon from '@mui/icons-material/Wallet';
import LinkIcon from '@mui/icons-material/Link';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { DialogContent, Button, Dialog, DialogActions, DialogContentText, DialogTitle, TextField, Toolbar, Tooltip } from '@mui/material';
import ShowcaseApp from './ShowcaseApp';
import BeyondSelect from './BeyondSelect';
import BeyondSelectURL from './BeyondSelectURL';


const Web3 = require('web3')
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            account: "TEST",
            characterData: null,
            activePane: null,
            issueDialogOpen: false,
            img_ip: 'https://img-dot-heph2-338519.uc.r.appspot.com',
            act_ip: 'https://act-dot-heph2-338519.uc.r.appspot.com',
            odt_ip: 'https://odt-dot-heph2-338519.uc.r.appspot.com',
            walletConnected: false,
        };

        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.snackMsg = this.snackMsg.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
        this.handleErrorMsg = this.handleErrorMsg.bind(this);
        this.handleWarnMsg = this.handleWarnMsg.bind(this);
        this.handleInfoMsg = this.handleInfoMsg.bind(this);
        this.handleIssueCancel = this.handleIssueCancel.bind(this);
        this.handleIssueTextFieldChanged = this.handleIssueTextFieldChanged.bind(this);
        this.handleIssueSend = this.handleIssueSend.bind(this);
        this.handleIssueDialogClose = this.handleIssueDialogClose.bind(this);
        this.handleIssueDialogOpen = this.handleIssueDialogOpen.bind(this);


    }


    handleMenuChange(m) {
        this.setState({ activePane: m });
    }

    handleInfoMsg(m) {
        this.snackMsg(m, "info");
    }
    handleWarnMsg(m) {
        this.snackMsg(m, "warning");
    }
    handleErrorMsg(m) {
        this.snackMsg(m, "error");
    }
    handleIssueCancel(m) {
        this.setState({ issueDialogOpen: false });
    }
    handleIssueTextFieldChanged(m) {
        this.setState({ issueText: m.target.value});
    }
    handleIssueSend(m) {
        console.log('issue', this.state.issueText)

        axios.get(this.state.img_ip + '/issue', {
            params: {
                issue: this.state.issueText
            }
        }).then(response => {
            console.log(response);
            this.handleInfoMsg('Issue sent.  Thanks!');
        }).catch(error => {
            this.handleErrorMsg('Error - Unable to send issue');
        }).finally(() => {
            this.setState({ 
                issueDialogOpen: false,
                issueText :""
             });
        })

    }
    handleIssueDialogClose(m) {
        this.setState({ issueDialogOpen: false });
    }
    handleIssueDialogOpen(m) {
        this.setState({ issueDialogOpen: true });
    }

    async connectWallet() {

        var provider = await detectEthereumProvider();

        if (provider == null) {
            throw exception("MetaMask not detected");
        }

        console.log('ethereum MetaMask', provider.isMetaMask)
        console.log('ethereum address', window.ethereum.selectedAddress)

        let acctRes = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('acctReq resp', acctRes);
        this.state.account = acctRes[0];
        let chainRes = await window.ethereum.request({ method: 'eth_chainId' });
        console.log('chainId resp', chainRes);
        this.state.chainId = chainRes;

        return this.state.account;

    }

    handleDisconnectWallet() {
        this.setState({
            productID: null,
            walletConnected:false,
        });
    }

    handleConnectWallet(e) {

        this.connectWallet().then((account) => {
            this.handleInfoMsg("Connected to MetaMask");
            this.setState({
                accout: account,
                walletConnected: true,
                activePane: e,
            });

        }).catch((e) => {
            this.setState({ walletConnected: false });
            this.handleErrorMsg("Unable to connect to MetaMask", e);

        })

    }
    snackMsg(m, s) {
        this.setState({
            snackSeverity: s,
            snackMessage: m,
            snackOpen: true
        });
    }
    handleInfoMsg(m) {
        this.snackMsg(m, "info");
    }
    handleWarnMsg(m) {
        this.snackMsg(m, "warning");
    }
    handleErrorMsg(m) {
        this.snackMsg(m, "error");
    }
    handleCloseSnackbar(e) {
        console.log('snack bar is closed');
        this.setState({ snackOpen: false });
    }


    componentDidMount() {
        console.log('mounting');


    }

    render() {
        console.log('rendering');

        let pane = <ShowcaseApp
            onConnectWallet={this.handleConnectWallet}
            img_ip={this.state.img_ip}
            act_ip={this.state.act_ip}
            odt_ip={this.state.odt_ip}
            onErrorMsg={this.handleErrorMsg}
            onWarnMsg={this.handleWarnMsg}
            onInfoMsg={this.handleInfoMsg} />

        if (this.state.activePane === 'title') {
            pane = <ShowcaseApp
                onConnectWallet={this.handleConnectWallet}
                img_ip={this.state.img_ip}
                act_ip={this.state.act_ip}
                odt_ip={this.state.odt_ip}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg} />
        }
        else if (this.state.activePane === 'new') {
            pane = <AvatarSelect
                onConnectWallet={this.handleConnectWallet}
                account={this.state.account}
                productID={this.state.productID}
                img_ip={this.state.img_ip}
                act_ip={this.state.act_ip}
                odt_ip={this.state.odt_ip}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg} />
        }
        else if (this.state.activePane === 'new-beyond') {
            pane = <BeyondSelect
                onConnectWallet={this.handleConnectWallet}
                account={this.state.account}
                productID={this.state.productID}
                img_ip={this.state.img_ip}
                act_ip={this.state.act_ip}
                odt_ip={this.state.odt_ip}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg} />
        }
        else if (this.state.activePane === 'new-beyond-url') {
            pane = <BeyondSelectURL
                onConnectWallet={this.handleConnectWallet}
                account={this.state.account}
                productID={this.state.productID}
                img_ip={this.state.img_ip}
                act_ip={this.state.act_ip}
                odt_ip={this.state.odt_ip}
                onErrorMsg={this.handleErrorMsg}
                onWarnMsg={this.handleWarnMsg}
                onInfoMsg={this.handleInfoMsg} />
        }

        return (
            <Box sx={{ flexGrow: 1 }} >
                <AppBar position="static">
                    <Toolbar>
                    <img src={NpcHubPng} alt="npchub" height="25" width="25" />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                        </Typography>
                        <Tooltip title="Home">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => { 
                                    this.handleDisconnectWallet();
                                    this.handleMenuChange('title') }}>
                                < HomeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Wallet">
                            <IconButton
                                size="large"
                                color="inherit"
                                disabled={this.state.walletConnected == true}
                                onClick={() => {
                                    this.handleConnectWallet('new');
                                }}>
                                < WalletIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Wallet">
                            <IconButton
                                size="large"
                                color="inherit"
                                disabled={this.state.walletConnected == true}
                                onClick={() => {
                                    this.handleConnectWallet('new-beyond');
                                }}>
                                < PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Wallet">
                            <IconButton
                                size="large"
                                color="inherit"
                                disabled={this.state.walletConnected == true}
                                onClick={() => {
                                    this.handleConnectWallet('new-beyond-url');
                                }}>
                                < LinkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Report Issue">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={this.handleIssueDialogOpen}>
                                <ReportProblemIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
                        <Grid item xs={4} sm={8} md={12}  >
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}  >
                            {pane}
                        </Grid>
                    </Grid>

                    <Snackbar open={this.state.snackOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                        <MuiAlert onClose={this.handleCloseSnackbar} severity={this.state.snackSeverity} sx={{ width: '100%' }}>
                            {this.state.snackMessage}
                        </MuiAlert>
                    </Snackbar>
                    <Dialog open={this.state.issueDialogOpen} onClose={this.handleIssueDialogClose}>
                        <DialogTitle>Report Issue</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                What did you experience?
                            </DialogContentText>
                            <TextField autoFocus
                                onChange={this.handleIssueTextFieldChanged}
                                margin="dense"
                                id="name"
                                label="Issue"
                                type="text"
                                fullWidth
                                variant="standard" />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleIssueCancel}>Cancel</Button>
                            <Button onClick={this.handleIssueSend}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Box>
        )
    }
}

export default App